<template lang="pug">
    .content-bdi
        .content-bdi__header
            HeadActor
        .content-bdi__body

            template(v-if="msisdnFounded === true || status_detail.status === 'loading'")
                .main-menu
                    .flex.items-center.gap-x-4
                        ul.menu
                            li: routerLink(to="call-history") Riwayat Panggilan
                            li: routerLink(to="location") Lokasi terakhir Tokoh
                            li: routerLink(to="location-history") Riwayat Lokasi
                .main-msisdn
                    routerView
            
            .empty-info.w-full.flex.items-center.justify-center.rounded(v-else)
                .block
                    b-icon.mb-4(name="info" size="2x")
                    p Belum ada Informasi Telekomunikasi Data
</template>

<script>
import HeadActor from '@/pages/actor/HeadActor';
import { mapState } from 'vuex';

export default {
    name: 'MsisdnOfActor',
    components: {
        HeadActor
    },
    data() {
        return {
            msisdnFounded: false,
        }
    },
    computed: {
        ...mapState('actor', [
            'status_detail',
            'detail',
        ]),
    },
    watch: {
        detail() {
            this.checkMsisdn();
        }
    },
    methods: {
        checkMsisdn() {
            if (this.detail && this.detail.msisdn && this.detail.msisdn.length > 0) {
                this.msisdnFounded = true;
            }
        },
    },
    mounted() {
        this.checkMsisdn();
    },
}
</script>

<style lang="sass">
.empty-info
    height: 300px
    border: 1px solid var(--border-theme)
</style>